.MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 4px !important;
}
/*
::-webkit-scrollbar {
    display: none;
  }
   */
.MuiAutocomplete-root .MuiAutocomplete-tag{
    background-color: #78c360 !important;
}