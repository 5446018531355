.MuiTable-root{
    margin-bottom: 1rem !important;
}
.css-1ircn5c{
    width:100%
}
/* 
.MuiPickersLayout-contentWrapper{
    grid-column: 1 !important;
    grid-row: -1 !important;
}
.MuiPickersLayout-root{
    display: flex !important;
    flex-direction: row-reverse !important;
    justify-content: flex-end !important;
}
.MuiTableCell-root{
    border: solid 0 !important;
}
/* .css-1b4ewkk-MuiTableCell-root {
    padding: 2px 1rem !important;
} */ 