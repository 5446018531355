#pagination_container {
  padding: 15px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.pagination_wrapper {
  min-width: 200px;
}
.per_page_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.page_per_row {
  margin-right: 10px;
  margin-top: 5px;
  padding: 3px 1px 3px 1px;
  max-width: 50px;
  margin-left: 5px;
  /* border: solid 1px rgba(43, 43, 43, 0.23); */
}

@media (max-width: 768px) {
  #pagination_container {
    display: flex;
  }
  .per_page_wrapper {
    justify-content: center;
  }
}
