.text_in_selected_customer_create_invoice {
  font-size: 0.85rem;
  font-weight: 300;
}
.item_table_header {
  min-width: 120px;
  font-size: 1rem;
  color: #7b809a;
  font-weight: 700 !important;
}
.view_invoice_details_text {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-top: 1rem;
}
.view_invoice_details_text_child_value {
  display: flex;
  width: 10pc;
  justify-content: flex-start;
}
.MuiTextField-root {
  width: 100%;
}
