.MuiListItemText-primary {
  font-size: "0.9rem";
}
.MuiTableRow-root {
  cursor: pointer !important;
}
.MuiButtonBase-root.MuiTab-root.Mui-selected {
  color: white !important;
}
.relative-class {
  position: relative;
  display: flex;
  gap: 15px;
}
.date-range-picker-wrapper {
  position: absolute;
  margin-top: 15px;
  top: 100%;
  left: -15px;
  z-index: 10;
}
.rdrDefinedRangesWrapper {
  display: none !important;
}
.rdrDateDisplayItemActive {
  border-color: #9cb52d;
}
.rdrDateDisplayWrapper {
  background-color: rgb(232 237 207);
}
